import { combineReducers } from 'redux';
import {
	createForms // optional
} from 'react-redux-form';
import {
	CHECK_EXISTING_USER_SUCCESS,
	CHECK_EXISTING_USER_ERROR,
	GET_OPTIONS,
	GET_OPTIONS_SUCCESS,
	GET_OPTIONS_ERROR,
	REFRESH_USER_SUCCESS,
	REFRESH_USER_ERROR,
	FREE_ACCESS,
	FREE_ACCESS_SUCCESS,
	FREE_ACCESS_ERROR,
	SEND_PASSWORD_RESET,
	SEND_PASSWORD_RESET_SUCCESS,
	SEND_PASSWORD_RESET_ERROR,
	VERIFY_PASSWORD_RESET_TOKEN,
	VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
	VERIFY_PASSWORD_RESET_TOKEN_ERROR,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	GET_TRIAL_SCHEDULES,
	TRIAL_SCHEDULES_RECEIVED,
	TRIAL_SCHEDULES_ERROR,
	GET_SCHEDULES,
	SCHEDULES_RECEIVED,
	SCHEDULES_ERROR,
	GET_HEALTH_SCORES,
	HEALTH_SCORES_RECEIVED,
	HEALTH_SCORES_ERROR,
	GET_MATCH,
	MATCH_SUCCESS,
	CLEAR_MATCH,
	MATCH_ERROR,
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT,
	LOGOUT_SUCCESS,
	CHECK_LOGIN,
	CHECK_LOGIN_SUCCESS,
	CHECK_LOGIN_ERROR,
	MAKE_SAMPLE_TOKEN,
	MAKE_SAMPLE_TOKEN_SUCCESS,
	MAKE_SAMPLE_TOKEN_ERROR,
	LOGGED_IN,
	NOT_LOGGED_IN,
	SIGNUP,
	SIGNUP_SUCCESS,
	SIGNUP_ERROR,
	USER_RESET,
	GET_PLAYER,
	PLAYER_SUCCESS,
	CLEAR_PLAYER,
	PLAYER_ERROR,
	GET_PRICES,
	GET_PRICES_SUCCESS,
	GET_PRICES_ERROR,
	PURCHASE_PLAN,
	PURCHASE_PLAN_SUCCESS,
	PURCHASE_PLAN_ERROR,
	TEAM_SCORES_RECEIVED,
	TEAM_SCORES_ERROR,
	VIEW_TEAM,
	VIEW_TEAM_SUCCESS,
	TEAM_SCHEDULE,
	TEAM_SCHEDULE_SUCCESS,
	TEAM_SCHEDULE_ERROR,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_ERROR,
	SOURCES,
	SOURCES_SUCCESS,
	SOURCES_ERROR,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_ERROR,
	SEARCH_GAMES,
	SEARCH_GAMES_SUCCESS,
	SEARCH_GAMES_ERROR,
	GAME_PLAYER_INFO_ERROR,
	GAME_PLAYER_INFO,
	GET_GAME_PLAYER_INFO
} from './constants';

import { adminDashboardOptions, adminDashboardSearch, adminDashboardCSV, adminDashboardCalculations, adminTeamHealthScoreNFL } from './adminReducer';

const initialState = {
	isLoaded: false,
	isLoading: false
	// data: {}
};

const userInitialState = {
	payload: null,
	login: false,
	logout: true
};

export const alertMessage = (state = {}, { type, payload = '' }) => {
	switch (type) {
		default:
			return state;
	}
};

export const generalAlert = (state = {}, { type, payload = '' }) => {
	switch (type) {
		case SIGNUP_SUCCESS:
		case LOGIN_SUCCESS:
			return {
				error: null
			};
		case SIGNUP_ERROR:
		case LOGIN_ERROR:
			return {
				error: payload.message
			};
		default:
			return state;
	}
};

export const options = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_OPTIONS:
			return {
				...state,
				isLoading: true
			};
		case GET_OPTIONS_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				data: payload
			};
		case GET_OPTIONS_ERROR:
			return {
				isLoading: false,
				error: payload.error
			};
		default:
			return state;
	}
};

export const sendemail = (state = userInitialState, { type, payload = '' }) => {
	switch (type) {
		case RESET_PASSWORD_SUCCESS:
			return {
				reset_success: true,
				message: payload.message
			};
		case RESET_PASSWORD_ERROR:
			return {
				reset_success: false,
				message: payload.message
			};
		case USER_RESET:
			return {
				sendemail: null
			};
		default:
			return state;
	}
};

export const trialSchedulesReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_TRIAL_SCHEDULES:
			return {
				...state,
				isLoading: true
			};
		case TRIAL_SCHEDULES_RECEIVED:
			return {
				isLoading: false,
				isLoaded: true,
				trialSchedules: payload
			};
		case TRIAL_SCHEDULES_ERROR:
			return {
				error: payload
			};
		default:
			return state;
	}
};

export const freeAccessReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case FREE_ACCESS:
			return {
				...state,
				isLoading: true
			};
		case FREE_ACCESS_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				emailValidated: true
			};
		case FREE_ACCESS_ERROR:
		case LOGIN_SUCCESS:
		case LOGOUT:
			return {
				isLoading: false,
				isLoaded: true
			};
		default:
			return state;
	}
};

export const signup = (state = {}, { type, payload = '' }) => {
	switch (type) {
		case SIGNUP:
			return {
				...state,
				isLoading: true
			};
		// case SIGNUP_SUCCESS:
		//   return {
		//     ...state,
		//     isLoading: false,
		//     success: true,
		//   };
		case SIGNUP_ERROR:
			return {
				isLoading: false,
				loggedIn: false,
				error: payload.message
			};
		default:
			return state;
	}
};

export const userReducer = (state = userInitialState, { type, payload = '' }) => {
	switch (type) {
		case CHECK_LOGIN:
			return {
				...state,
				isLoading: true
			};
		case CHECK_LOGIN_SUCCESS:
		case CHECK_LOGIN_ERROR:
			return {
				...state,
				isLoading: false
			};
		case MAKE_SAMPLE_TOKEN:
			return {
				...state,
				isLoading: true
			};
		case MAKE_SAMPLE_TOKEN_SUCCESS:
			return {
				...state,
				isLoading: false,
				token: payload.token
			};
		case MAKE_SAMPLE_TOKEN_ERROR:
			return {
				...state,
				isLoading: false
			};
		case USER_RESET:
			return {
				login: false,
				userReset: true
			};
		case LOGIN:
			return {
				...state,
				isLoading: true,
				isLoaded: false
			};
		case REFRESH_USER_SUCCESS:
			return {
				...state,
				...payload
			};
		case LOGGED_IN:
		case LOGIN_SUCCESS:
		case SIGNUP_SUCCESS:
			return {
				// ...state,
				login: true,
				isLoading: false,
				isLoaded: true,
				...payload,
				sportsID: state.sportsID,
				sportsName: state.sportsName
			};
		case NOT_LOGGED_IN:
		case LOGIN_ERROR:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				login: false
			};
		case REFRESH_USER_ERROR:
		case LOGOUT:
			return {
				login: false,
				logout: true,
				isLoading: false,
				isLoaded: true
			};
		case LOGOUT_SUCCESS:
			return {
				// ...state,
				isLoading: false,
				isLoaded: true,
				message: payload,
				login: false,
				logout: true,
				name: null
			};
		case SIGNUP:
			return {
				...state,
				isLoading: true,
				isLoaded: false
			};
		case SIGNUP_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				message: payload,
				registerError: true
			};
		case CREATE_SUBSCRIPTION:
			return {
				...state,
				isLoading: false
			};
		default:
			return state;
	}
};

export const userActions = (state = {}, { type, payload = '' }) => {
	switch (type) {
		case LOGIN:
			return {
				...state,
				isLoading: true
			};
		case LOGIN_SUCCESS:
		case CREATE_SUBSCRIPTION:
			return {
				...state,
				isLoading: false
			};
		case LOGIN_ERROR:
		case CREATE_SUBSCRIPTION_ERROR:
			return {
				...state,
				isLoading: false,
				message: payload,
				loginError: true
			};
		case SIGNUP_ERROR:
			return {
				...state,
				isLoading: false,
				message: payload,
				registerError: true
			};
		case CHECK_EXISTING_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				existing_user: true,
				message: 'Email already exists'
			};
		case CHECK_EXISTING_USER_ERROR:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				existing_user: false,
				message: null
			};
		default:
			return state;
	}
};

export const passwordReset = (state = {}, { type, payload = '' }) => {
	switch (type) {
		case SEND_PASSWORD_RESET:
			return {
				...state,
				isLoading: true
			};
		case VERIFY_PASSWORD_RESET_TOKEN:
		case RESET_PASSWORD:
			return {
				...state,
				isLoading: true
			};
		case SEND_PASSWORD_RESET_SUCCESS:
			return {
				isLoading: false,
				passwordResetSent: true,
				message: payload.message
			};
		case SEND_PASSWORD_RESET_ERROR:
			return {
				isLoading: false,
				passwordResetSent: false,
				error: payload.message
			};
		case VERIFY_PASSWORD_RESET_TOKEN_SUCCESS:
			return {
				isLoading: false,
				verifyPasswordToken: true,
				message: payload.message,
				email: payload.email
			};
		case VERIFY_PASSWORD_RESET_TOKEN_ERROR:
			return {
				isLoading: false,
				verifyPasswordToken: false,
				error: payload.message
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				isLoading: false,
				passwordResetSuccess: true,
				message: payload.message
			};
		case RESET_PASSWORD_ERROR:
			return {
				isLoading: false,
				passwordReset: false,
				message: payload.message
			};
		default:
			return state;
	}
};

export const schedulesReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_SCHEDULES:
		case SEARCH_GAMES:
			return {
				...state,
				isLoading: true
			};
		case SCHEDULES_RECEIVED:
			return {
				isLoading: false,
				isLoaded: true,
				data: payload,
				offensiveSkills: payload.offensiveSkills,
				offensiveLine: payload.offensiveLine,
				defensiveLine: payload.defensiveLine,
				defensiveSecondary: payload.defensiveSecondary
			};
		case SCHEDULES_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload
			};
		case SEARCH_GAMES_SUCCESS:
			return {
				isLoading: false,
				nfl: payload.nfl
			};
		case SEARCH_GAMES_ERROR:
			return {
				isLoading: false,
				error: payload.message
			};
		default:
			return state;
	}
};

export const healthScoresReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_HEALTH_SCORES:
			return {
				...state,
				isLoading: true
			};
		case HEALTH_SCORES_RECEIVED:
			return {
				isLoading: false,
				isLoaded: true,
				categoryAverages: payload
			};
		case HEALTH_SCORES_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload
			};
		default:
			return state;
	}
};

export const teamsReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case VIEW_TEAM:
			return {
				...state,
				isLoading: true
			};
		case TEAM_SCORES_RECEIVED:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				data: payload
			};
		case VIEW_TEAM_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				teamInfo: payload.teamInfo,
				players: payload.playerInfo
			};
		case TEAM_SCORES_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload
			};
		case CLEAR_MATCH:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				teamInfo: null
			};
		default:
			return state;
	}
};

export const playerInfoReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_GAME_PLAYER_INFO:
		case GAME_PLAYER_INFO:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				playersInfo: payload
			};
		case GAME_PLAYER_INFO_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload
			};
		default:
			return state;
	}
};

export const teamSchedulesReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case TEAM_SCHEDULE:
			return {
				...state,
				isLoading: true
			};
		case TEAM_SCHEDULE_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				data: payload
			};
		case TEAM_SCHEDULE_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload
			};
		case CLEAR_MATCH:
			return {
				isLoading: false,
				isLoaded: true
			};
		default:
			return state;
	}
};

export const matchReducer = (state = initialState, { type, payload = '', match }) => {
	switch (type) {
		case GET_MATCH:
			return {
				...state,
				isLoading: true
			};
		case MATCH_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				match: payload.match,
				homePlayers: payload.homePlayers,
				awayPlayers: payload.awayPlayers
			};
		case CLEAR_MATCH:
			return {
				isLoading: false,
				isLoaded: true
			};
		case MATCH_ERROR:
			return {
				error: payload
			};
		default:
			return state;
	}
};

export const playerReducer = (state = initialState, { type, payload = '', match }) => {
	switch (type) {
		case GET_PLAYER:
			return {
				...state,
				isLoading: true
			};
		case PLAYER_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				data: payload
			};
		case CLEAR_PLAYER:
			return {
				isLoading: false,
				isLoaded: true
			};
		case PLAYER_ERROR:
			return {
				error: payload
			};
		default:
			return state;
	}
};

export const pricesReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_PRICES:
			return {
				...state,
				isLoading: true
			};
		case GET_PRICES_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				prices: payload.prices
			};
		case GET_PRICES_ERROR:
			return {
				error: payload
			};
		default:
			return state;
	}
};

export const purchasePlanReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case PURCHASE_PLAN:
			return {
				...state,
				isLoading: true
			};
		case PURCHASE_PLAN_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				purchasePlan: payload
			};
		case PURCHASE_PLAN_ERROR:
			return {
				error: payload
			};
		default:
			return state;
	}
};

export const subscriptionsReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case CREATE_SUBSCRIPTION:
			return {
				...state,
				isLoading: true
			};
		case CREATE_SUBSCRIPTION_ERROR:
			return {
				...state,
				isLoading: false,
				message: payload
			};
		case CREATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CANCEL_SUBSCRIPTION:
			return {
				...state,
				isLoading: true
			};
		case CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				message: payload.message
			};
		case CANCEL_SUBSCRIPTION_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload.message
			};
		default:
			return state;
	}
};

export const sourcesReducer = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case SOURCES:
			return {
				...state,
				isLoading: true
			};
		case SOURCES_SUCCESS:
			return {
				isLoading: false,
				isLoaded: true,
				message: payload.message
			};
		case SOURCES_ERROR:
			return {
				isLoading: false,
				isLoaded: true,
				error: payload.message
			};
		default:
			return state;
	}
};

export default combineReducers({
	options,
	generalAlert,
	...createForms({
		user: userReducer,
		freeAccess: freeAccessReducer,
		userActions,
		sendemail,
		adminDashboardOptions,
		adminDashboardSearch,
		adminDashboardCSV,
		purchasePlan: purchasePlanReducer,
		subscriptionActions: subscriptionsReducer,
		sources: sourcesReducer,
		adminTeamHealthScoreNFL,
		passwordReset,
		alertMessage
	}),
	trialSchedules: trialSchedulesReducer,
	schedules: schedulesReducer,
	healthScores: healthScoresReducer,
	teams: teamsReducer,
	playersInfo: playerInfoReducer,
	teamSchedule: teamSchedulesReducer,
	match: matchReducer,
	player: playerReducer,
	prices: pricesReducer,
	adminDashboardCalculations
});
