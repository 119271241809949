export const CHECK_EXISTING_USER = 'CHECK_EXISTING_USER';
export const CHECK_EXISTING_USER_SUCCESS = 'CHECK_EXISTING_USER_SUCCESS';
export const CHECK_EXISTING_USER_ERROR = 'CHECK_EXISTING_USER_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const GET_TRIAL_SCHEDULES = 'GET_TRIAL_SCHEDULES';
export const TRIAL_SCHEDULES_RECEIVED = 'TRIAL_SCHEDULES_RECEIVED';
export const TRIAL_SCHEDULES_ERROR = 'TRIAL_SCHEDULES_ERROR';
export const GET_GAME_PLAYER_INFO = 'GET_GAME_PLAYER_INFO';
export const GET_SCHEDULES = 'GET_SCHEDULES';
export const SCHEDULES_RECEIVED = 'SCHEDULES_RECEIVED';
export const SCHEDULES_ERROR = 'SCHEDULES_ERROR';
export const GET_HEALTH_SCORES = 'GET_HEALTH_SCORES';
export const HEALTH_SCORES_RECEIVED = 'HEALTH_SCORES_RECEIVED';
export const HEALTH_SCORES_ERROR = 'HEALTH_SCORES_ERROR';
export const GET_MATCH = 'GET_MATCH';
export const MATCH_SUCCESS = 'MATCH_SUCCESS';
export const MATCH_ERROR = 'MATCH_ERROR';
export const CLEAR_MATCH = 'CLEAR_MATCH';
export const VIEW_ROSTER = 'VIEW_ROSTER';
export const CLEAR_ROSTER = 'CLEAR_ROSTER';
export const CLEAR_ROSTER_ID = 'CLEAR_ROSTER_ID';
export const GET_PLAYER = 'GET_PLAYER';
export const PLAYER_SUCCESS = 'PLAYER_SUCCESS';
export const GAME_PLAYER_INFO = 'GAME_PLAYER_INFO';
export const GAME_PLAYER_INFO_ERROR = 'GAME_PLAYER_INFO_ERROR';
export const CLEAR_PLAYER = 'CLEAR_PLAYER';
export const PLAYER_ERROR = 'PLAYER_ERROR';
export const GET_PLAYER_HISTORY = 'GET_PLAYER_HISTORY';
export const PLAYER_HISTORY_SUCCESS = 'PLAYER_HISTORY_SUCCESS';
export const PLAYER_HISTORY_ERROR = 'PLAYER_HISTORY_ERROR';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_ERROR = 'CHECK_LOGIN_ERROR';
export const MAKE_SAMPLE_TOKEN = 'MAKE_SAMPLE_TOKEN';
export const MAKE_SAMPLE_TOKEN_SUCCESS = 'MAKE_SAMPLE_TOKEN_SUCCESS';
export const MAKE_SAMPLE_TOKEN_ERROR = 'MAKE_SAMPLE_TOKEN_ERROR';
export const LOGGED_IN = 'LOGGED_IN';
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN';
export const USER_RESET = 'USER_RESET';
export const REFRESH_USER = 'REFRESH_USER';
export const REFRESH_USER_SUCCESS = 'REFRESH_USER_SUCCESS';
export const REFRESH_USER_ERROR = 'REFRESH_USER_ERROR';

// Login/Signup
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET';
export const SEND_PASSWORD_RESET_SUCCESS = 'SEND_PASSWORD_RESET_SUCCESS';
export const SEND_PASSWORD_RESET_ERROR = 'SEND_PASSWORD_RESET_ERROR';
export const VERIFY_PASSWORD_RESET_TOKEN = 'VERIFY_PASSWORD_RESET_TOKEN';
export const VERIFY_PASSWORD_RESET_TOKEN_SUCCESS = 'VERIFY_PASSWORD_RESET_TOKEN_SUCCESS';
export const VERIFY_PASSWORD_RESET_TOKEN_ERROR = 'VERIFY_PASSWORD_RESET_TOKEN_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// Get Options
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_ERROR = 'GET_OPTIONS_ERROR';

// Purchase Section

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

export const GET_PRICES = 'GET_PRICES';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR';
export const PURCHASE_PLAN = 'PURCHASE_PLAN';
export const PURCHASE_PLAN_SUCCESS = 'PURCHASE_PLAN_SUCCESS';
export const PURCHASE_PLAN_ERROR = 'PURCHASE_PLAN_ERROR';
export const CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION';
export const CHECK_SUBSCRIPTION_SUCCESS = 'CHECK_SUBSCRIPTION_SUCCESS';
export const CHECK_SUBSCRIPTION_ERROR = 'CHECK_SUBSCRIPTION_ERROR';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';

// Team Constants
export const TEAM_SCORES = 'TEAM_SCORES';
export const TEAM_SCORES_RECEIVED = 'TEAM_SCORES_RECEIVED';
export const TEAM_SCORES_ERROR = 'TEAM_SCORES_ERROR';
export const VIEW_TEAM = 'VIEW_TEAM';
export const VIEW_TEAM_SUCCESS = 'VIEW_TEAM_SUCCESS';
export const VIEW_TEAM_ERROR = 'VIEW_TEAM_ERROR';
export const TEAM_SCHEDULE = 'TEAM_SCHEDULE';
export const TEAM_SCHEDULE_SUCCESS = 'TEAM_SCHEDULE_SUCCESS';
export const TEAM_SCHEDULE_ERROR = 'TEAM_SCHEDULE_ERROR';

// Admin Dashboard
export const ADMIN_DASHBOARD_SEARCH_SUBMIT = 'ADMIN_DASHBOARD_SEARCH_SUBMIT';
export const ADMIN_DASHBOARD_SEARCH_SUCCESS = 'ADMIN_DASHBOARD_SEARCH_SUCCESS';
export const ADMIN_DASHBOARD_SEARCH_ERROR = 'ADMIN_DASHBOARD_SEARCH_ERROR';
export const ADMIN_DASHBOARD_CHANGE_SUBMIT = 'ADMIN_DASHBOARD_CHANGE_SUBMIT';
export const ADMIN_DASHBOARD_CHANGE_SUCCESS = 'ADMIN_DASHBOARD_CHANGE_SUCCESS';
export const ADMIN_DASHBOARD_CHANGE_ERROR = 'ADMIN_DASHBOARD_CHANGE_ERROR';
export const ADMIN_DASHBOARD_INSERT_SUBMIT = 'ADMIN_DASHBOARD_INSERT_SUBMIT';
export const ADMIN_DASHBOARD_INSERT_SUCCESS = 'ADMIN_DASHBOARD_INSERT_SUCCESS';
export const ADMIN_DASHBOARD_INSERT_ERROR = 'ADMIN_DASHBOARD_INSERT_ERROR';
export const GET_DASHBOARD_OPTIONS = 'GET_DASHBOARD_OPTIONS';
export const GET_DASHBOARD_OPTIONS_SUCCESS = 'GET_DASHBOARD_OPTIONS_SUCCESS';
export const GET_DASHBOARD_OPTIONS_ERROR = 'GET_DASHBOARD_OPTIONS_ERROR';
export const GET_ADMIN_CSV = 'GET_ADMIN_CSV';
export const GET_ADMIN_CSV_SUCCESS = 'GET_ADMIN_CSV_SUCCESS';
export const GET_ADMIN_CSV_ERROR = 'GET_ADMIN_CSV_ERROR';
export const RUN_CALCULATIONS = 'RUN_CALCULATIONS';
export const RUN_CALCULATIONS_SUCCESS = 'RUN_CALCULATIONS_SUCCESS';
export const RUN_CALCULATIONS_ERROR = 'RUN_CALCULATIONS_ERROR';
export const GET_TEAM_HEALTH_SCORE_NFL = 'GET_TEAM_HEALTH_SCORE_NFL';
export const GET_TEAM_HEALTH_SCORE_NFL_SUCCESS = 'GET_TEAM_HEALTH_SCORE_NFL_SUCCESS';
export const GET_TEAM_HEALTH_SCORE_NFL_ERROR = 'GET_TEAM_HEALTH_SCORE_NFL_ERROR';

// Audit
export const AUDIT = 'AUDIT';
export const AUDIT_SUCCESS = 'AUDIT_SUCCESS';
export const AUDIT_ERROR = 'AUDIT_ERROR';

// Marketing
export const SOURCES = 'SOURCES';
export const SOURCES_SUCCESS = 'SOURCES_SUCCESS';
export const SOURCES_ERROR = 'SOURCES_ERROR';

// Check Free Access
export const FREE_ACCESS = 'FREE_ACCESS';
export const FREE_ACCESS_SUCCESS = 'FREE_ACCESS_SUCCESS';
export const FREE_ACCESS_ERROR = 'FREE_ACCESS_ERROR';

// Collect Credit Card
export const COLLECT_CREDIT_CARD = 'COLLECT_CREDIT_CARD';
export const COLLECT_CREDIT_CARD_SUCCESS = 'COLLECT_CREDIT_CARD_SUCCESS';
export const COLLECT_CREDIT_CARD_ERROR = 'COLLECT_CREDIT_CARD_ERROR';

// ADMIN
export const SEARCH_GAMES = 'SEARCH_GAMES';
export const SEARCH_GAMES_SUCCESS = 'SEARCH_GAMES_SUCCESS';
export const SEARCH_GAMES_ERROR = 'SEARCH_GAMES_ERROR';
export const SUBMIT_ADMIN_CHANGES = 'SUBMIT_ADMIN_CHANGES';
export const SUBMIT_ADMIN_CHANGES_SUCCESS = 'SUBMIT_ADMIN_CHANGES_SUCCESS';
export const SUBMIT_ADMIN_CHANGES_ERROR = 'SUBMIT_ADMIN_CHANGES_ERROR';
