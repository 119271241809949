import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import './App.css';
import './global/css/globalStyle.css';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Articles from './components/Articles';
import NFLMatchup from './components/Matchup/NFLMatchup';
import NFLTeam from './components/Team/NFLTeam';
import NBAMatchup from './components/Matchup/NBAMatchup';
import NBATeam from './components/Team/NBATeam';
import Player from './components/Player';
import About from './components/About';
import Whitepaper from './components/Whitepaper';
import Confirmation from './components/Confirmation';
import Footer from './components/Footer';
import Admin from './components/Admin';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initGA } from './components/Tracking';
import { gtag } from './config.js';
import Updates from './components/Updates';
import SoccerMatchup from './components/Matchup/SoccerMatchup';
import SoccerTeam from './components/Team/SoccerTeam';
import SoccerUpdates from './components/Updates/soccer';
import TeamHealthScore from './components/TeamHealthScore';
import AlertMessage from './components/AlertMessage';
import Cancel from './components/Confirmation/cancel';
import UpcomingGames from './components/Dashboard/upcomingGames';
import FantasyScoresImg from './global/img/carousel/fantasyscores.jpg';
import PowerImg from './global/img/carousel/powerofinsideinjuries.jpg';
import ImpactImg from './global/img/carousel/impactofinjuries.jpg';
import DoctorsEdgeImg from './global/img/carousel/doctorsedge.png';

function App(props) {
	const { user, userActions, schedules, dispatch, subscriptionActions, collectCC } = props;
	useEffect(() => {
		initGA(gtag);

		if (!user?.login) {
			dispatch({ type: 'MAKE_SAMPLE_TOKEN' });
		}
		dispatch({ type: 'GET_OPTIONS' });
		if (window.location.href.includes('logout')) {
			dispatch({ type: 'LOGOUT' });
		}
		if (user.login) {
			dispatch({ type: 'CHECK_LOGIN' });
		}
		dispatch({ type: 'GET_PRICES' });
		if (user?.login) {
			dispatch({ type: 'REFRESH_USER' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (userActions.isLoading || schedules.isLoading || subscriptionActions?.isLoading || collectCC?.isLoading) {
		return <Loading />;
	}

	const carouselArticleImages = {
		FantasyScoresImg,
		PowerImg,
		ImpactImg,
		DoctorsEdgeImg
	};

	const assignCurrentSport = () => {
		const sport = window.location.pathname.split('/')[1]?.toUpperCase();
		if (sport?.toUpperCase() === 'SOCCER') {
			dispatch(actions.change('user.sportsID', 3));
			dispatch(actions.change('user.sportsName', 'soccer'));
		} else if (sport?.toUpperCase() === 'NBA') {
			dispatch(actions.change('user.sportsID', 2));
			dispatch(actions.change('user.sportsName', 'nba'));
		} else {
			dispatch(actions.change('user.sportsID', 1));
			dispatch(actions.change('user.sportsName', 'nfl'));
		}
	};

	const page = window.location.pathname.split('/')[1]?.toUpperCase();
	let hideNavbarFooter = false;
	if (page?.toUpperCase() === 'SIGNUP' || page?.toUpperCase() === 'LOGIN' || page?.toUpperCase() === 'RESETPASSWORD') {
		hideNavbarFooter = true;
	}

	return (
		<div className='App'>
			<div>
				<AlertMessage />
			</div>
			{!hideNavbarFooter ? <Navbar subscriberPage={page?.toUpperCase() === 'SUBSCRIBE'} /> : null}
			<Router>
				<Routes>
					<Route exact path='/' element={<Dashboard carouselArticleImages={carouselArticleImages} />} />
					<Route exact path='/signup' element={<Signup />} />
					<Route exact path='/login' element={<Signup />} />
					<Route exact path='/updates' element={<Updates />} />
					{user?.admin && <Route exact path='/admin' element={<Admin />} />}
					{user?.admin && <Route exact path='/admin/team_health_score' element={<TeamHealthScore />} />}
					<Route exact path='/confirmation' element={<Confirmation />} />
					<Route exact path='/cancel' element={<Cancel />} />
					<Route exact path='/whitepaper' element={<Whitepaper />} />
					<Route exact path='/articles/:articlename' element={<Articles carouselArticleImages={carouselArticleImages} />} />
					<Route exact path='/about' element={<About />} />
					<Route exact path='/:sport?/dashboard/:urlparam' element={<Dashboard carouselArticleImages={carouselArticleImages} />} />
					<Route exact path='/:sport?/dashboard' element={<Dashboard carouselArticleImages={carouselArticleImages} />} />
					<Route exact path='/nba' element={<UpcomingGames assignCurrentSport={assignCurrentSport} page={page} />} />
					<Route exact path='/:sport?/upcominggames' element={<UpcomingGames assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/nfl/matchup/:id' element={<NFLMatchup assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/nfl/team/:id' element={<NFLTeam assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/nba/matchup/:id' element={<NBAMatchup assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/nba/team/:id' element={<NBATeam assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/soccer/matchup/:id' element={<SoccerMatchup assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/soccer/team/:id' element={<SoccerTeam assignCurrentSport={assignCurrentSport} />} />
					<Route exact path='/soccer/updates' element={<SoccerUpdates />} />
					<Route exact path='/:sport?/player/:id' element={<Player />} />
					<Route exact path='/resetpassword' element={<Signup />} />
				</Routes>
			</Router>
			{!hideNavbarFooter ? <Footer /> : null}
		</div>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	freeAccess: state.freeAccess,
	user: state.user,
	userActions: state.userActions,
	schedules: state.schedules,
	prices: state.prices,
	subscriptionActions: state.subscriptionActions,
	sources: state.sources,
	collectCC: state.collectCC,
	adminTeamHeathScoreNFL: state.adminTeamHealthScoreNFL
});

// export default App;
export default connect(mapStateToProps)(App);
