import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../style.css';
import { Col } from 'react-bootstrap';
import PlayerHealthScores from './playerHealthScores';
import PlayerColumn from './playerColumns';
import { Country, State, City } from 'country-state-city';
import { Modal } from 'react-bootstrap';
import Logo from '../../../global/img/logo_full_white.png';
import InputFields from '../../InputFields';
import { Form, Control } from 'react-redux-form';

function TeamCard(props) {
	const { user, players, sportsName, nbaPage } = props;

	const handleLinkClick = (e) => {
		if (!user?.login && nbaPage) {
		  e.preventDefault();
		  setShowModal(true)
		}
	};

	const [showModal, setShowModal] = useState(false);

	const handleSignup = () => {
		dispatch({
			type: 'SIGNUP',
			payload: { signup: userActions.signup }
		});
	};

	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedState, setSelectedState] = useState('');

	useEffect(() => {
		// Fetch the list of countries when the component mounts
		setCountries(Country.getAllCountries());
	}, []);

	const handleCountryChange = (event) => {
		const countryCode = countries.find(country => country.name === event.target.value);
		setSelectedCountry(countryCode?.isoCode);
		setStates(State.getStatesOfCountry(countryCode?.isoCode));
		setSelectedState('');
		setCities([]);
	};

	const handleStateChange = (event) => {
		const stateCode = states.find(state => state.name === event.target.value);
		setSelectedState(stateCode?.isoCode);
		setCities(City.getCitiesOfState(selectedCountry, stateCode?.isoCode));
	};

	return (
		<React.Fragment>
			{players &&
				players.map((player, i) => (
					<Col id={player.team_id} lg={4} key={i} className='cardCol col-xl-5ths col-md-6 col-sm-6 col-xs-12' style={{ height: '300px' }}>
						<div className='cardColDiv'>
							<Col lg={12} className='cardViewGameCol'>
								<div className='cardSpreadDiv'>{user?.login ? <PlayerHealthScores player={player} /> : <div style={{ padding: '27.5px 0' }} />}</div>
								<PlayerColumn player={player} userAllowed={user?.login} sportsID={user?.sportsID} />
								<Link
									className='cardViewGameButton hover-delay'
									title='View Player'
									to={{
										pathname: `/${sportsName || 'nfl'}/player/${player.player_id}`
									}}
									onClick={handleLinkClick}
								>
									VIEW PLAYER
								</Link>
							</Col>
						</div>
					</Col>
				))}
				<Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
					<Modal.Body>
						<section className='signupMainSection'>
							<div className='signupMainDiv'>
								<div className='text-center'>
									<img src={Logo} alt='Inside Injuries Logo' className='signupLogo' />
									<p className='loginFlowText'>
										Already have an account?
										<a href='/login' className='loginFlowLink ps-2'>
											Login here
										</a>
									</p>
								</div>
								<div className='signupFormDiv'>
	
									<>
									<Form model="userActions.signup" onSubmit={() => handleSignup()}>
									<div className="inputCol">
										<InputFields model=".first_name" inputType="text" type="text" placeholder="Enter your first name" label="First Name" />
									</div>
									<div className="inputCol">
										<InputFields model=".email" inputType="text" type="email" placeholder="Enter your email" label="Email" />
									</div>
									<div className="inputCol">
										<InputFields model=".password" inputType="text" type="password" placeholder="Enter your password" label="Password" />
									</div>
									<div className="inputCol">
										<label class="formInputsLabel">Country</label>
										<Control.select model=".country" onChange={handleCountryChange} required={countries.length > 0}>
											<option value="">Select a country</option>
											{countries.map((country) => (
												<option key={country.isoCode} value={country.name}>
													{country.name}
												</option>
											))}
										</Control.select>
									</div>
									<div className="inputCol">
										<label class="formInputsLabel">State</label>
										<Control.select model=".state" onChange={handleStateChange} disabled={!selectedCountry} required={states.length > 0}>
											<option value="">Select a state</option>
											{states.map((state) => (
												<option key={state.isoCode} value={state.name}>
													{state.name}
												</option>
											))}
										</Control.select>
									</div>
									<div className="inputCol">
										<label class="formInputsLabel">City</label>
										<Control.select model=".city" disabled={!selectedState} required={cities.length > 0}>
											<option value="">Select a city</option>
											{cities.map((city) => (
												<option key={city.name} value={city.name}>
													{city.name}
												</option>
											))}
										</Control.select>
									</div>
									<div className='inputCol'>
										<InputFields model='.zipcode' inputType='text' type='zipcode' placeholder='Enter your zip code' label='ZIP Code' />
									</div>
									<div className="w-100">
										{/* eslint-disable-next-line react/jsx-pascal-case */}
										<Control.button model="userActions.signup" className="loginButton" type="submit">
											SIGN UP
										</Control.button>
									</div>
								</Form>
								<p className='signupHeaderText'>Join for free and get access to all injury data.</p>

									</>
									
								</div>
							</div>
						</section>
					</Modal.Body>
				</Modal>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	schedules: state.schedules,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.source
});

export default connect(mapStateToProps)(TeamCard);
