import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { highlightColumnColor } from '../../utils/dashboardCalculations';

class teamHealthScore extends Component {
	componentDidMount() {}

	render() {
		const { player } = this.props;
		const highlightColor = highlightColumnColor(player.team_gaming_score, 'cardSpread');
		return (
			<div>
				<Row className='cardSpreadRow'>
					<Col lg={12} className={`cardHighlight ${highlightColor}`}>
						<div className='cardSpreadDiv'>
							<h4 className='cardSpread'>
								<span className='cardSpreadNoBetText'>Player Experience</span>
								<span className='cardSpreadNumberTeam'>{player.experience || '0'}</span>
							</h4>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default teamHealthScore;
