import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './player.css';
import { Row, Col } from 'react-bootstrap';

import UserHeader from '../UserHeader';
import { formatDate } from '../utils/utils';
import { highlightColumnColor, checkHealthScoreStatus, showHealthyToReturnDate, calculateHeight } from '../utils/dashboardCalculations';
import { Line } from 'rc-progress';
import DashboardTip from './../Tooltip/dashboardTip';

function Player(props) {
	const { dispatch, player, user, togglePlayerView } = props;
	let { playerID } = props;
	useEffect(() => {
		window.scrollTo(0, 0);
		const url = window.location.pathname.split('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
		playerID = playerID || url[url.length - 1];
		dispatch({
			type: 'GET_PLAYER',
			payload: { playerID }
		});
	}, []);

	const title = 'Health Scores for Player';
	// const redirectURL = `${user.sportsID}/dashboard`;
	if (player.isLoading)
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	const { sportsName, sportsID } = user;

	if (!player || !player.data) return null;

	const playerInfo = player && player.data && player.data.playerInfo[0];
	const playerHistory = player.data && player.data.playerHistory;
	const healthStatus = playerInfo && playerInfo.health_score ? checkHealthScoreStatus(playerInfo.health_score, 'player') : '';
	const teamHealthStatus = playerInfo && playerInfo.team_health_score ? checkHealthScoreStatus(playerInfo.team_health_score, 'team') : '';
	const healthScoreDesc = `Health score represents the overall impact of a players' past injuries to give an overall performance ranking`;
	const teamHealthScoreDesc = `Team health score represents the overall impact of all starting players' health scores to give an overall performance ranking`;
	const showReturnDate = showHealthyToReturnDate(playerInfo?.recovery_return_date, 3);
	return !playerInfo ? (
		<div className='playerMainDiv'>
			<UserHeader matchup={true} player={true} backgroundColor={'#f7f7f7'} title={title} />
			<h1 style={{ textAlign: 'center' }}>No info found for player</h1>
		</div>
	) : (
		<section className='playerMainSection'>
			<div className='playerMainDiv'>
				<UserHeader matchup={true} player={true} backgroundColor={'#f7f7f7'} returnFunction={() => togglePlayerView()} />
				{/* <div className="playerInfoMobileTeamLogoDiv">
                <img
                  src={playerInfo.team_logo}
                  alt="Team Logo"
                  className="playerInfoMobileTeamLogo"
                />
              </div> */}
				<Row className='playerInfoRow'>
					<Col lg={2} className='playerInfoCol'>
						<img src={playerInfo.team_logo} alt={`${playerInfo.player_name}`} className='playerInfoImg' />
					</Col>
					<Col lg={10} className='playerInfoCol'>
						<div className='playerInfoDiv'>
							<div className='playerInfoTopDiv'>
								<p className='playerInfoJersey'>{playerInfo.jersey}</p>
								<p className='playerInfoName'>{playerInfo.nickname || playerInfo.player_name}</p>
								<p className='playerInfoPosition'>({playerInfo.position})</p>
							</div>
							<div className='playerInfoStatsDiv playerInfoStatsDesktopDiv'>
								{sportsID !== 3 ? (
									<>
										<p className='playerInfoStats'>
											<span className='playerInfoStatsTitle'>HEIGHT</span>
											<span className='playerInfoStatsValue'>{calculateHeight(playerInfo.height)}</span>
										</p>
										<p className='playerInfoStats'>
											<span className='playerInfoStatsTitle'>WEIGHT</span>
											<span className='playerInfoStatsValue'>{playerInfo.weight}</span>
										</p>
									</>
								) : null}
								<p className='playerInfoStats'>
									<span className='playerInfoStatsTitle'>AGE</span>
									<span className='playerInfoStatsValue'>{playerInfo.age}</span>
								</p>
								{sportsID !== 3 ? (
									<p className='playerInfoStats'>
										<span className='playerInfoStatsTitle'>YRS IN {sportsName && sportsName.toUpperCase()}</span>
										<span className='playerInfoStatsValue'>{playerInfo.experience || 'N/A'}</span>
									</p>
								) : null}
								<p className='playerInfoStats'>
									<span className='playerInfoStatsTitle'>STATUS</span>
									<span className='playerInfoStatsValue'>{playerInfo.status}</span>
								</p>
								<p className='playerInfoStats'>
									<span className='playerInfoStatsTitle'>HEALTHY TO RETURN</span>
									<span className='playerInfoStatsValue'>{showReturnDate && playerInfo.recovery_return_date ? formatDate(true, playerInfo.recovery_return_date.split('T')[0]) || '' : 'No Recent Injury'}</span>
								</p>
								<p className='playerInfoStats'>
									<span className='playerInfoStatsTitle'>INJURY RISK</span>
									<span className='playerInfoStatsValue'>{playerInfo?.irc_total}%</span>
								</p>
							</div>
							<div className='playerInfoStatsDiv playerInfoStatsMobileDiv'>
								<Row>
									{user?.sportsID !== 3 ? (
										<>
											<Col xs={6}>
												<p className='playerInfoStats'>
													<span className='playerInfoStatsTitle'>HEIGHT</span>
													<span className='playerInfoStatsValue'>{calculateHeight(playerInfo.height)}</span>
												</p>
											</Col>
											<Col xs={6}>
												<p className='playerInfoStats'>
													<span className='playerInfoStatsTitle'>WEIGHT</span>
													<span className='playerInfoStatsValue'>{playerInfo.weight}</span>
												</p>
											</Col>
										</>
									) : null}
									<Col xs={6}>
										{' '}
										<p className='playerInfoStats'>
											<span className='playerInfoStatsTitle'>AGE</span>
											<span className='playerInfoStatsValue'>{playerInfo.age}</span>
										</p>
									</Col>
									<Col xs={6}>
										{' '}
										{sportsID !== 3 ? (
											<p className='playerInfoStats'>
												<span className='playerInfoStatsTitle'>YRS IN {sportsName && sportsName.toUpperCase()}</span>
												<span className='playerInfoStatsValue'>{playerInfo.experience || 'N/A'}</span>
											</p>
										) : null}
									</Col>
									<Col xs={6}>
										{' '}
										<p className='playerInfoStats'>
											<span className='playerInfoStatsTitle'>STATUS</span>
											<span className='playerInfoStatsValue'>{playerInfo.status}</span>
										</p>
									</Col>
									<Col xs={6}>
										{' '}
										<p className='playerInfoStats'>
											<span className='playerInfoStatsTitle'>HEALTHY TO RETURN</span>
											<span className='playerInfoStatsValue'>{showReturnDate && playerInfo.recovery_return_date ? formatDate(true, playerInfo.recovery_return_date) : 'No Recent Injury'}</span>
										</p>
									</Col>
								</Row>
							</div>

							<Row>
								<Col lg={8}>
									<div className='playerInfoProgressDiv'>
										<p className='playerInfoProgressLabel'>
											Player Health Score:
											<span className={`playerInfoProgressValue ${healthStatus.color}`}>{healthStatus.status}</span>
										</p>
										<div className='playerInfoProgressBarDiv displayTable'>
											<div className='displayTableCell'>
												<Line percent={playerInfo.health_score ? (playerInfo.health_score / 10) * 100 : 100} strokeWidth='4' trailWidth='4' trailColor='rgba(21, 21, 21, .06)' strokeColor={highlightColumnColor(playerInfo.health_score, 'cardSpread', 'playerhealth', true)} />
											</div>
											<span className='playerInfoProgressBarValue displayTableCell'>{playerInfo.health_score ? `${playerInfo.health_score}/10` : `10/10`}</span>
											<span className='displayTableCell'>
												<DashboardTip title='PLAYER HEALTH SCORE' description={healthScoreDesc} component={'player_health_score'} />
											</span>
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg={8}>
									<div className='playerInfoProgressDiv'>
										<p className='playerInfoProgressLabel'>
											Team Health Score:
											<span className={`playerInfoProgressValue ${teamHealthStatus.color}`}>{teamHealthStatus.status}</span>
										</p>
										<div className='playerInfoProgressBarDiv displayTable'>
											<div className='displayTableCell'>
												<Line percent={(playerInfo.team_health_score / 10) * 100} strokeWidth='4' trailWidth='4' trailColor='rgba(21, 21, 21, .06)' strokeColor={highlightColumnColor(playerInfo.team_health_score, 'cardSpread', 'teamhealth', true)} />
											</div>
											<span className='playerInfoProgressBarValue displayTableCell'>{playerInfo.team_health_score}/10</span>
											<span className='displayTableCell'>
												<DashboardTip title='TEAM HEALTH SCORE' description={teamHealthScoreDesc} component={'team_health_score'} />
											</span>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				<Row className='playerTotalInjuryRow container-fluid'>
					<Col lg={3} className='playerTotalInjuryCol'>
						<div className='playerTotalInjuryDiv'>
							<p className='playerTotalInjury'>TOTAL INJURIES LAST 12 MONTHS:</p>
							<p className='playerTotalInjuryValue'>
								{/* {playerInfo.total_1_year_injuries_count} */}
								{playerHistory?.length}
							</p>
						</div>
					</Col>
					<Col lg={3} className='playerTotalInjuryCol'>
						<div className='playerTotalInjuryDiv'>
							<p className='playerTotalInjury'>TOTAL INJURIES LAST 3 YEARS:</p>
							<p className='playerTotalInjuryValue'>{playerInfo.total_3_year_injuries_count || 0}</p>
						</div>
					</Col>
				</Row>
				<div className='playerHistoryDiv'>
					<p className='playerTotalInjuryHistory'>TOTAL INJURIES LAST 12 MONTHS:</p>
					{playerHistory &&
						playerHistory.map((history, i) => (
							<Row key={i} className='playerHistoryRow'>
								<Col lg={2} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>INJURY</p>
									<p className='playerHistoryValue'>{history.body_part}</p>
								</Col>
								{/* <Col lg={5} className="playerHistoryCol">
                        <p className="playerHistoryLabel">DESCRIPTION</p>
                        <p className="playerHistoryValue">
                          {history.admin_injury_grade || history.final_injury_grade}
                        </p>
                      </Col> */}
								<Col lg={2} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>INJURY GRADE</p>
									<p className='playerHistoryValue'>{history.admin_injury_grade || history.final_injury_grade}</p>
								</Col>
								<Col lg={3} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>DATE</p>
									<p className='playerHistoryValue'>{history.injury_date ? formatDate(true, history.injury_date) : 'No Recent Injury'}</p>
								</Col>
							</Row>
						))}
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	match: state.match,
	player: state.player,
	playerHistory: state.playerHistory,
	user: state.user
});

export default connect(mapStateToProps)(Player);
